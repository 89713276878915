import React, { Suspense, lazy, useEffect, useState } from "react";
import "./CourseDetails.css";
import { useLocation } from "react-router-dom";
import { Button, Form, Row, Table } from "react-bootstrap";
import {
  getStudentName,
  //   getAttendance,
  //   getTransactions,
  updateEnrollment,
} from "../../axios/services/userService";
import { toast } from "react-toastify";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AttendanceTab from "../../components/Tabs/AttendanceTab";
import TransactionsTab from "../../components/Tabs/TransactionsTab";
import ExamMarkTab from "../../components/Tabs/ExamMarkTab";
import profilePic from "../../assets/profile-icon-9.png";
import profilePicSample from "../../assets/sample-dp.jpg";
import StudentCard from "../../components/StudentCard/StudentCard";
import Exams from "../Exams/Exams";
import Leaves from "../Leaves/Leaves";
import Fees from "../Fees/Fees";
import UserProfile from "../UserProfile/UserProfile";
import BottomNavbar from "../../components/Navbar/BottomNavbar";

// const AttendanceTab = lazy(() => import("../../components/Tabs/AttendanceTab"));
// const ExamMarkTab = lazy(() => import("../../components/Tabs/ExamMarkTab"));
// const TransactionsTab = lazy(() =>
//   import("../../components/Tabs/TransactionsTab")
// );

const CourseDetails = ({ activeTab }) => {
  const location = useLocation();
  const { course } = location.state;
  // const [activeTab, setActiveTab] = useState("exams");
  // const [initialCourseDetails, setInitialCourseDetails] = useState(course);
  // const [courseDetails, setCourseDetails] = useState(course);
  // const [studentName, setStudentName] = useState("");
  // const [activeKey, setActiveKey] = useState(null);

  const studentId = localStorage.getItem("studentId");

  const renderActiveTab = () => {
    switch (activeTab) {
      case "exams":
        return <Exams enrollmentId={course.name} />;
      case "leaves":
        return <Leaves studentId={studentId} />;
      case "fees":
        return <Fees enrollmentId={course.name} />;
      case "user-profile":
        return <UserProfile courseDetails={course} />;
      default:
        return <Exams enrollmentId={course.name} />;
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await getStudentName(studentId);
  //     setStudentName(response);
  //   };
  //   fetchData();
  // }, []);

  // const handleTabSelect = (key) => {
  //   setActiveKey((prevKey) => (prevKey === key ? null : key));
  // };

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setCourseDetails((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value,
  //     }));
  //   };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     const updatedDetails = {};
  //     for (let key in courseDetails) {
  //       if (courseDetails[key] !== initialCourseDetails[key]) {
  //         updatedDetails[key] = courseDetails[key];
  //       }
  //     }
  //     const res = await updateEnrollment(updatedDetails, courseDetails?.name);
  //     console.log(res);
  //     if (res.success) {
  //       //   setCourseDetails(res.updatedDetails);
  //       toast.success(res.message);
  //     } else {
  //       toast.error("Error occurred. Try again");
  //     }
  //   };

  return (
    <>
      <div className="container">
        <div className="course-details-page-wrapper">
          <div className="course-details-page">
            <StudentCard courseDetails={course} />
            <hr />
            {renderActiveTab()}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
