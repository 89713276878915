import React, { useState } from "react";
import { FaBook, FaUser, FaMoneyBill, FaCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./BottomNavbar.css";

const BottomNavbar = ({handleSetActiveTab, activeTab}) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [activePage, setActivePage] = useState(location.pathname);

  const handleNavigation = (path, title) => {
    handleSetActiveTab(path, title);
    // navigate(path, { state: { title } });
  };

  return (
    <div className="bottom-navbar">
      <div
        className={`nav-item ${activeTab === "exams" ? "active" : ""}`}
        onClick={() => handleNavigation("exams", "Exams & Progress Report")}
      >
        <FaBook />
        <span>Exams</span>
      </div>
      <div
        className={`nav-item ${activeTab === "leaves" ? "active" : ""}`}
        onClick={() => handleNavigation("leaves", "Leaves")}
      >
        <FaCalendarAlt />
        <span>Leaves</span>
      </div>
      <div
        className={`nav-item ${activeTab === "fees" ? "active" : ""}`}
        onClick={() => handleNavigation("fees", "Fees")}
      >
        <FaMoneyBill />
        <span>Fees</span>
      </div>
      <div
        className={`nav-item ${activeTab === "user-profile" ? "active" : ""}`}
        onClick={() => handleNavigation("user-profile", "Profile")}
      >
        <FaUser />
        <span>Profile</span>
      </div>
    </div>
  );
};

export default BottomNavbar;
