import React, { Suspense, lazy, useEffect, useState } from "react";
import "./StudentCard.css";
import { getStudentName } from "../../axios/services/userService";
import profilePic from "../../assets/profile-icon-9.png";
import profilePicSample from "../../assets/sample-dp.jpg";

const StudentCard = ({ courseDetails }) => {
  const [studentName, setStudentName] = useState("");

  const studentId = localStorage.getItem("studentId");

  useEffect(() => {
    const fetchData = async () => {
      const response = await getStudentName(studentId);
      setStudentName(response);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="student-details-section">
        <div className="profile-pic-wrapper">
          <div className="profile-pic">
            <img src={profilePic} alt="profile" className="profile-image" />
            {/* <a href="https://www.freepik.com/free-photo/young-bearded-man-with-striped-shirt_9660042.htm#query=user%20profile&position=23&from_view=keyword&track=ais_user&uuid=f734dc61-5e8e-4433-b3fa-50f0c4a2499d">Image by wayhomestudio</a> on Freepik */}
          </div>
        </div>
        <div className="student-name">
          <b>
            {studentName.first_name} {studentName.last_name}
          </b>
          <div className="course-name">{courseDetails.program}</div>
        </div>
      </div>
    </>
  );
};

export default StudentCard;
