import {
  MDBInput,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import React from "react";

const OtpField = ({ value, onChange, otpSent }) => {
  return (
          <MDBValidationItem
            className="col-md-12"
            feedback={otpSent ? "" : "Provide a valid otp"}
            invalid={!otpSent}
          >
            <div className="col-md-12">
              <MDBInput
                label="Enter OTP"
                type="number"
                name="otp"
                value={value}
                onChange={onChange}
                required
              />
            </div>
          </MDBValidationItem>
  );
};

export default OtpField;
