import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { getAttendance } from "../../axios/services/userService";
import './Leaves.css'

const Leaves = ({studentId}) => {
  const [attendance, setAttendance] = useState(null);

  // const studentId = localStorage.getItem('studentId')
    
  useEffect(() => {
    const fetchAttendance = async () => {
      try {
        const response = await getAttendance(studentId);
        setAttendance(response?.attendanceData || null);
      } catch (error) {
        console.error("Failed to fetch attendance data:", error);
        setAttendance(null); // Set to null on error to show fallback message
      }
    };
    fetchAttendance();
  }, []);
  
  return (
    <div className="attendance-section">
      <div className="attendance-table">
        {attendance ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Month</th>
                <th>Year</th>
                <th>Overall</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Days</b>
                </td>
                <td>{attendance?.this_month?.total_days}</td>
                <td>{attendance?.this_year?.total_days}</td>
                <td>{attendance?.overall?.total_days}</td>
              </tr>
              <tr>
                <td>
                  <b>Present</b>
                </td>
                <td>{attendance?.this_month?.total_present}</td>
                <td>{attendance?.this_year?.total_present}</td>
                <td>{attendance?.overall?.total_present}</td>
              </tr>
              <tr>
                <td>
                  <b>Absent</b>
                </td>
                <td>{attendance?.this_month?.total_absent}</td>
                <td>{attendance?.this_year?.total_absent}</td>
                <td>{attendance?.overall?.total_absent}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>Dear Student, your data import is still in process. Please check back later after a few days.</p>
        )}
      </div>
    </div>
  );
};

export default Leaves;
