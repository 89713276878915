import { axiosUtilityInstance } from "../axios";

export const sendOtp = async (mobileNumber) => {
  const { data } = await axiosUtilityInstance.get(`/sendOtp/${mobileNumber}`);
  if (data.success) return data;
};

export const validateOTP = async (mobileNumber, otp) => {
  const { data } = await axiosUtilityInstance.post("/validateOTP", {
    mobileNumber,
    otp,
  });
  return data;
};

export const generateRazorPay = async (course) => {
  const { data } = await axiosUtilityInstance.post("/payment", { course });
  if (data) {
    return data;
  }
};

export const orderVerifyPayment = async (paymentData) => {
  const { data } = await axiosUtilityInstance.post("/verify-payment", {paymentData})
  if (data) {
    return data;
  }
}