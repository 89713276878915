import React from "react";
import "./LoginNavbar.css";
import { Link } from "react-router-dom";
import logo from "../../assets/XylemLogoNew.png.webp";

const LoginNavbar = () => {
  return (
    <div className="login-nav-wrapper">
      <nav className="login-nav">
        <Link>
          <img src={logo} alt="Xylem Logo" className="logo" />
        </Link>
      </nav>
    </div>
  )
}

export default LoginNavbar