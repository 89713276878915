import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { getCourseList } from "../../axios/services/userService";
import "./CourseEnrolled.css";
import { useNavigate } from "react-router-dom";
import Payment from "../../components/Payment";

const CourseEnrolled = () => {
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const studentId = localStorage.getItem("studentId");
    const response = await getCourseList(studentId);
    setCourseList(response);
  };

  const handleRowClick = (course) => {
    navigate("/course-details", { state: { course } });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="course-enrolled-wrapper">
          <div className="course-enrolled-page">
            <h2>COURSES ENROLLED</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Course</th>
                  <th>Offered Amount</th>
                  {/* <th>Balance Amount</th> */}
                </tr>
              </thead>
              <tbody>
                {courseList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.program}
                      <br />
                        <span
                          style={{
                            color: "blue",
                            // textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRowClick(item)}
                        >
                          View Details
                        </span></td>
                      <td>
                        Rs. {item.new_offered_amount} 
                      </td>

                      {/* <td>
                        {item.course_fee_balance > 0 ? (
                          <Payment item={item} />
                        ) : (
                          <span>No balance</span>
                        )}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseEnrolled;
