import axios from 'axios';

const userBaseUrl = process.env.REACT_APP_USER_BASE_URL;
const utilityBaseUrl = process.env.REACT_APP_UTILITY_BASE_URL;

export const axiosUserInstance = axios.create({
    baseURL: userBaseUrl
})

export const axiosUtilityInstance = axios.create({
    baseURL: utilityBaseUrl
})

// export const axiosUserInstance = axios.create({
//     baseURL: 'https://student-portal-63vc.onrender.com/user'
// })

// export const axiosUtilityInstance = axios.create({
//     baseURL: 'https://student-portal-63vc.onrender.com/utility/'
// })

// export const axiosUserInstance = axios.create({
//     baseURL: 'https://xylemportalbackend.binalyto.com/user'
// })

// export const axiosUtilityInstance = axios.create({
//     baseURL: 'https://xylemportalbackend.binalyto.com/utility/'
// })