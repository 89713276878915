import React, { useState } from "react";
import "./Navbar.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/XylemLogoNewIcon.png.webp";

const Navbar = ({title}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("studentId");
    navigate("/");
  };

  // const getTitle = () => {
  //   // Check if there's a state passed with the current route
  //   if (location.state && location.state.title) {
  //     return location.state.title;
  //   }

  //   // Default titles based on pathname
  //   switch (location.pathname) {
  //     case "/course-details":
  //       return "Course Details";
  //     case "/user-profile":
  //       return "Profile";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className="wrapper">
      <nav>
        <Link to="/course-list" className="title">
          <img src={logo} alt="Xylem Logo" className="logo" />
        </Link>
        {/* <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <FaBars size="large" color="black" />
        </div> */}
        <div className="current-title">{title}</div>
        <ul
          className={menuOpen ? "open" : ""}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <li>
            <NavLink to="/course-list">Enrolled Courses</NavLink>
          </li>
          <li>
            <NavLink to="/user-profile">Profile</NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={handleLogout}>
              Logout
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
