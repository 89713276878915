import React, { useEffect, useState } from "react";
import { getTransactions } from "../../axios/services/userService";
import { Table } from "react-bootstrap";
import './Fees.css'

const Fees = ({ courseName }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (courseName) {
        const response = await getTransactions(courseName);
        setTransactions(response.transactions);
      }
    };
    fetchTransactions();
  }, []);

  return (
    <div className="transactions-section">
      <div className="transactions-table">
        {transactions.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Fee Category</th>
                <th>Paid Amount</th>
                <th>Payment Date</th>
                <th>Payment Status</th>
                <th>Mode of Payment</th>
                <th>Reference No.</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{transaction.name}</td>
                    <td>{transaction.category}</td>
                    <td>Rs. {transaction.paid_amount}</td>
                    <td>{transaction.transaction_date}</td>
                    <td>{transaction.approval_status}</td>
                    <td>{transaction.mode_of_payment}</td>
                    <td>{transaction.reference_no}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p>Dear Student, your data import is still in process. Please check back later after a few days.</p>
        )}
      </div>
    </div>
  );
};

export default Fees;
