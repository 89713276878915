import React, { useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import { getExamMarks } from "../../axios/services/userService";
import "./Exams.css";
import StudentCard from "../../components/StudentCard/StudentCard";

const Exams = ({ enrollmentId }) => {
  const [examMarks, setExamMarks] = useState([]);

  useEffect(() => {
    const fetchExamMarks = async () => {
      const { examMarks } = await getExamMarks(enrollmentId);
      setExamMarks(examMarks);
    };
    fetchExamMarks();
  }, [enrollmentId]);

  const isEmptyData = (data) => {
    return (
      data.length === 0 ||
      (data.length === 1 && Object.keys(data[0]).length === 0)
    );
  };

  return (
    <>
      {/* <StudentCard courseDetails={}/> */}
      <div className="exam-mark-section">
        <div className="exam-mark-table">
          <Accordion defaultActiveKey="">
            {isEmptyData(examMarks) ? (
              <span>
                Dear Student, your data import is still in process. Please check
                back later after a few days.
              </span>
            ) : (
              examMarks.map((exam, index) => {
                const date = Object.keys(exam)[0];
                const results = exam[date].results;
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{date}</Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Subjects</th>
                            <th>Marks Earned</th>
                            <th>Total Marks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results.map((result, i) => (
                            <tr key={i}>
                              <td>{result?.subject}</td>
                              <td>{result?.student_mark}</td>
                              <td>{result?.exam_total}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Exams;
