import React from "react";
import axios from "axios";
import {
  generateRazorPay,
  orderVerifyPayment,
} from "../axios/services/utilityService";
import useRazorpay from "react-razorpay";
import { toast } from "react-toastify";

const Payment = ({ item }) => {
  const [Razorpay] = useRazorpay();

  const handlePayment = async (item) => {
    // const frappeOrder = await createFrappeOrder()
    const { order } = await generateRazorPay(item);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: order.amount,
      currency: "INR",
      name: "Xylem Corporate Office",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: async (res) => {
        const paymentData = {
          orderCreationId: order.id,
          razorpayPaymentId: res.razorpay_payment_id,
          razorpayOrderId: res.razorpay_order_id,
          razorpaySignature: res.razorpay_signature,
        };
        verifyPayment(paymentData);
      },
      //   prefill: {
      //     //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      //     name: "Gaurav Kumar", //your customer's name
      //     email: "gaurav.kumar@example.com",
      //     contact: "9000090000", //Provide the customer's phone number for better conversion rates
      //   },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#20a348",
      },
      timeout: 600,
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();

    const verifyPayment = async (paymentData) => {
      const data = await orderVerifyPayment(paymentData);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    };
  };

  return (
    <>
      Rs. {item.course_fee_balance}
      <br />
      <span
        id="rzp-button1"
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handlePayment(item, e);
        }}
      >
        Pay Now
      </span>
      {/* <div>
        <button id="rzp-button1" onClick={handlePayment}>
          Pay Now
        </button>
      </div> */}
    </>
  );
};
export default Payment;
