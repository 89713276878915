import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseEnrolled from "./pages/CourseEnrolled/CourseEnrolled";
import Login from "./pages/Login/Login";
import UserProfile from "./pages/UserProfile/UserProfile";
import Navbar from "./components/Navbar/Navbar";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
import UserPrivateRoutes from "./utils/UserPrivateRoutes";
import LoginNavbar from "./components/Navbar/LoginNavbar";
import BottomNavbar from "./components/Navbar/BottomNavbar";
import E404 from "./pages/E404/E404";
import Exams from "./pages/Exams/Exams";
import Leaves from "./pages/Leaves/Leaves";
import Fees from "./pages/Fees/Fees";
import { useState } from "react";
import Register from "./pages/Register/Register";

function App() {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("exams");
  const [title, setTitle] = useState("Exams & Progress Report");

  const handleSetActiveTab = (tab, newTitle) => {
    setActiveTab(tab);
    setTitle(newTitle);
  };

  return (
    <>
      <ToastContainer />
      {location.pathname === "/" || location.pathname === "/register" ? (
        <LoginNavbar />
      ) : (
        <>
          <Navbar title={location.pathname === "/course-details" ? title : ""}/>
          {location.pathname === "/course-details" && (
            <BottomNavbar activeTab={activeTab} handleSetActiveTab={handleSetActiveTab} />
          )}
        </>
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route element={<UserPrivateRoutes />}>
          <Route path="/course-list" element={<CourseEnrolled />} />
          <Route path="/course-details" element={<CourseDetails activeTab={activeTab}/>} />
          <Route path="/user-profile" element={<UserProfile />} />
        </Route>
        {/* Handle unknown routes */}
        <Route path="*" element={<E404 />} />
      </Routes>
    </>
  );
}

export default App;
